@import './styles/_fonts.scss';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 300% ;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 1045px) {
  body {
    overflow-x: hidden;
  }
  .plansSlick .slick-list {
    overflow: visible !important;
  }
}

@media screen and (max-width: 400px) {
  .col-xs {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.row-noMargin{
  margin-right: 0px !important;
  margin-left: 0px !important;
}
